<template>
  <v-stepper-content step="2" class="touchpoints-creation-preview">
    <UiContainer with-actions large>
      <div class="tw-flex tw-flex-col tw-gap-6">
        <div>
          <UiTitle :title="$t('touchpoints.creation.product.preview.title')" />
          <UiSubtitle
            v-if="products.length === existingProducts.length"
            :subtitle="
              $tc('touchpoints.creation.product.preview.subtitle.allOk', products.length, {
                number: products.length,
              })
            "
          />
          <UiSubtitle
            v-if="!existingProducts.length"
            :subtitle="
              $tc('touchpoints.creation.product.preview.subtitle.allKO', products.length, {
                number: products.length,
              })
            "
          />
          <UiSubtitle
            v-if="existingProducts.length < products.length && existingProducts.length"
            :subtitle="
              $tc('touchpoints.creation.product.preview.subtitle.mixed', existingProducts.length, {
                number: products.length,
                results: existingProducts.length,
              })
            "
          />
        </div>
        <div>
          <v-data-table
            disable-sort
            hide-default-footer
            :item-class="itemClass"
            :items-per-page="-1"
            :headers="headers"
            :items="products"
            class="elevation-1 touchpoints-creation-preview__table tw-mb-24"
          >
            <template v-slot:[`item.number`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.touchpointConfiguration.activated`]="{ item }">
              <v-tooltip v-if="item.touchpointConfiguration.activated" max-width="300" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary" small>{{ icons.mdiInformation }}</v-icon>
                </template>
                <span>{{ $t('touchpoints.creation.product.preview.table.alreadyExists') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
        <UiActions large>
          <v-btn color="primary" outlined rounded @click="onPrev">
            <v-icon left>{{ icons.mdiArrowLeft }}</v-icon>
            {{ $t('button.previous') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            rounded
            @click="onSubmit"
            :disabled="!existingProducts.length || loading"
            :loading="loading"
          >
            {{ $t('button.create') }}
            <v-icon right>{{ icons.mdiArrowRight }}</v-icon>
          </v-btn>
        </UiActions>
      </div>
    </UiContainer>
  </v-stepper-content>
</template>

<script>
import { mdiArrowRight, mdiArrowLeft, mdiInformation } from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'TouchpointCreationProductPreview',
  components: {
    UiTitle,
    UiSubtitle,
    UiActions,
    UiContainer,
  },
  props: {
    products: {
      type: Array,
      rqeuired: false,
      default: () => [],
    },
    existingProducts: {
      type: Array,
      rqeuired: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiArrowRight,
      mdiArrowLeft,
      mdiInformation,
    },
  }),
  computed: {
    headers() {
      return [
        { text: '#', value: 'number' },
        { text: this.$t('touchpoints.creation.product.preview.table.headers.reference'), value: 'sku' },
        { text: this.$t('touchpoints.creation.product.preview.table.headers.brand'), value: 'productBrand' },
        { text: this.$t('touchpoints.creation.product.preview.table.headers.name'), value: 'name' },
        { text: '', value: 'touchpointConfiguration.activated' },
      ]
    },
  },
  methods: {
    itemClass(item) {
      if (item.status === 'OK' && !item.touchpointConfiguration.activated) {
        return 'statusOK'
      }
      if (item.status === 'OK' && item.touchpointConfiguration.activated) {
        return 'statusAlready'
      }
      return 'statusKO'
    },
    onPrev() {
      this.$emit('prev')
    },
    onSubmit() {
      this.$emit('create')
    },
  },
}
</script>

<style lang="scss">
$color-ko: #ef9a9a;

.touchpoints-creation-preview {
  &__table {
    .statusKO {
      position: relative;
      color: $color-ko;

      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 4px solid $color-ko;
        content: '';
      }
    }

    tr {
      &:hover {
        background: none !important;
      }
    }
  }
}
</style>
