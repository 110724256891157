<template>
  <v-stepper-content step="2">
    <UiContainer with-actions large v-if="createdTouchpoint">
      <div class="tw-flex tw-flex-col tw-gap-6">
        <div>
          <UiTitle :title="$t('touchpoints.creation.custom.summary.title')" centered />
          <UiSubtitle :subtitle="$t('touchpoints.creation.custom.summary.subtitle')" centered />
        </div>
        <div class="tw-flex tw-flex-col tw-items-center tw-flex-grow-0">
          <UiTitle :title="$t('touchpoints.creation.custom.summary.summary.title')" centered />
          <div class="tw-flex tw-flex-col tw-items-start">
            <div class="tw-bg-black tw-p-4 tw-rounded-lg tw-mt-6 tw-flex tw-flex-row">
              <img
                :src="createdTouchpoint.qrCodeUrl.svgUrl"
                alt="QR Code"
                width="200"
                class="tw-rounded-lg tw-w-12 tw-h-12 tw-mr-4"
              />
              <div class="tw-flex tw-flex-col">
                <div class="tw-font-bold tw-text-white">{{ createdTouchpoint.url.source }}</div>
                <div class="tw-text-white">{{ createdTouchpoint.description }}</div>
              </div>
            </div>

            <div class="tw-flex tw-items-center tw-ml-4">
              <v-icon small>{{ icons.mdiArrowRightBottom }}</v-icon>
              <span>{{ createdTouchpoint.url.destination }}</span>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-6 tw-mt-6 tw-items-center">
          <div class="tw-flex tw-flex-col">
            <v-btn color="primary" primary rounded :disabled="downloading" :loading="downloading" @click="download">
              <span>{{ $t('touchpoints.creation.custom.summary.cta.download.label') }}</span>
            </v-btn>
            <div class="tw-text-xs tw-text-gray-400 tw-text-center tw-mt-1">
              {{ $t('touchpoints.creation.custom.summary.cta.download.hint') }}
            </div>
          </div>
          <router-link :to="{ name: 'TouchpointsList' }" class="tw-font-medium">
            {{ $t('touchpoints.creation.custom.summary.cta.goTouchpoints') }}
          </router-link>
        </div>
      </div>
    </UiContainer>
  </v-stepper-content>
</template>

<script>
import { mdiArrowRightBottom } from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'TouchpointsCreationCustomSummary',
  components: {
    UiTitle,
    UiSubtitle,
    UiContainer,
  },
  props: {
    createdTouchpoint: {
      type: Object,
    },
    downloading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiArrowRightBottom,
    },
  }),
  methods: {
    download() {
      this.$emit('download')
    },
  },
}
</script>
