<template>
  <v-stepper-content step="1">
    <v-fade-transition leave-absolute>
      <UiContainer with-actions large v-if="!loading">
        <form class="tw-flex tw-flex-col tw-gap-2" @submit.prevent="onSubmit" novalidate autocomplete="off">
          <div class="required-asterisk">
            <UiTitle :title="$t('touchpoints.creation.product.references.title')" />
            <UiSubtitle :subtitle="$t('touchpoints.creation.product.references.subtitle')" />
          </div>
          <div>
            <div class="touchpoints-creation__items tw-h-full tw-flex tw-items-center">
              <v-textarea
                v-model.trim="references"
                :error-messages="referencesErrors"
                no-resize
                flat
                solo
                auto-grow
                outlined
                :key="currentStep"
                :placeholder="$t('touchpoints.creation.product.references.field.references.placeholder')"
              />
            </div>
          </div>
          <UiActions large>
            <v-btn color="primary" outlined rounded @click="onPrev">
              <v-icon left>{{ icons.mdiArrowLeft }}</v-icon>
              {{ $t('button.previous') }}
            </v-btn>
            <v-spacer />
            <v-btn color="primary" rounded type="submit" :disabled="loading">
              {{ $t('button.next') }}
              <v-icon right>{{ icons.mdiArrowRight }}</v-icon>
            </v-btn>
          </UiActions>
        </form>
      </UiContainer>
      <UiContainer large v-else class="tw-h-full">
        <div class="tw-flex tw-flex-col">
          <UiTitle :title="$t('touchpoints.creation.product.references.loadingTitle')" centered />
          <UiSubtitle :subtitle="$t('touchpoints.creation.product.references.loadingSubtitle')" centered />
          <v-progress-linear class="tw-w-full tw-mt-6" indeterminate background-color="white" height="12" rounded>
          </v-progress-linear>
        </div>
      </UiContainer>
    </v-fade-transition>
  </v-stepper-content>
</template>

<script>
import { mdiArrowRight, mdiArrowLeft } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'TouchpointCreationProductReferences',
  components: {
    UiTitle,
    UiSubtitle,
    UiActions,
    UiContainer,
  },
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  data: () => ({
    references: '',
    icons: {
      mdiArrowRight,
      mdiArrowLeft,
    },
  }),
  computed: {
    referencesErrors() {
      const errors = []
      if (!this.$v.references.$dirty) return errors
      !this.$v.references.required && errors.push(this.$t('error.required'))
      !this.validateSize && errors.push(this.$t('error.touchpoints.size'))
      return errors
    },
    validateSize() {
      return this.references.split(/[\s,\n]+/).length < 200
    },
  },
  methods: {
    onPrev() {
      this.$emit('prev')
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid && this.validateSize) {
        this.$emit('validate', this.references.split(/[\s,\n]+/))
      }
    },
  },
  validations() {
    return {
      references: {
        required,
      },
    }
  },
}
</script>
