<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div v-else class="touchpoints-creation">
      <TouchpointsCreationTypePicker
        v-if="!hasStepper"
        :touchpoint-type="touchpointType"
        @change="updateTouchpointType"
        @launchStepper="launchStepper"
      />
      <v-stepper v-else-if="hasStepper" class="tw-h-full tw-flex tw-flex-col" flat v-model="currentStep" tile>
        <v-stepper-header class="touchpoints-creation__header" :class="{ 'theme--dark': $vuetify.theme.dark }">
          <template v-for="(step, idx) in steps">
            <v-stepper-step :complete="currentStep > idx + 1" :step="idx + 1" :key="`steps${idx}`">
              {{ $t(`touchpoints.creation.stepper.header.${touchpointType}.${idx + 1}`) }}
            </v-stepper-step>

            <v-divider v-if="idx + 1 < steps" :key="`divider${idx}`"></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-items
          class="touchpoints-creation__items tw-h-full tw-overflow-y-auto"
          :class="{ 'theme--dark': $vuetify.theme.dark }"
        >
          <TouchpointsCreationCustomForm
            v-if="touchpointType === 'custom'"
            :base-url-list="brandSettings.data"
            :loading="stepValidating"
            :is-touchpoint-exists="isTouchpointExists"
            @prev="resetStepper"
            @create="createCustomTouchpoint"
            @editing="resetField"
          />
          <TouchpointsCreationCustomSummary
            v-if="touchpointType === 'custom'"
            :created-touchpoint="createdTouchpoints[0]"
            :downloading="downloading"
            @download="downloadQRCodes"
          />

          <TouchpointsCreationProductReferences
            v-if="touchpointType === 'product'"
            :current-step="currentStep"
            :loading="stepValidating"
            @validate="getCurrentProducts"
            @prev="resetStepper"
          />
          <TouchpointsCreationProductPreview
            v-if="touchpointType === 'product'"
            :products="products"
            :existing-products="computedOkProducts"
            :loading="stepValidating"
            @prev="previousStep"
            @create="createProductTouchpoints"
          />
          <TouchpointsCreationProductSummary
            v-if="touchpointType === 'product'"
            :created-touchpoints="createdTouchpoints"
            :downloading="downloading"
            @download="downloadQRCodes"
          />
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { chunk } from '@/utils/utilities.util'
import { downloadFromUrl } from '@/utils/zip.util'
import TouchpointsCreationTypePicker from '@/components/Touchpoints/Creation/TypePicker.vue'
import TouchpointsCreationCustomForm from '@/components/Touchpoints/Creation/Custom/Form.vue'
import TouchpointsCreationCustomSummary from '@/components/Touchpoints/Creation/Custom/Summary.vue'
import TouchpointsCreationProductReferences from '@/components/Touchpoints/Creation/Product/References.vue'
import TouchpointsCreationProductPreview from '@/components/Touchpoints/Creation/Product/Preview.vue'
import TouchpointsCreationProductSummary from '@/components/Touchpoints/Creation/Product/Summary.vue'

export default {
  name: 'TouchpointsCreation',
  components: {
    TouchpointsCreationTypePicker,
    TouchpointsCreationCustomForm,
    TouchpointsCreationCustomSummary,
    TouchpointsCreationProductReferences,
    TouchpointsCreationProductPreview,
    TouchpointsCreationProductSummary,
  },
  data: () => ({
    currentStep: 1,
    hasStepper: false,
    touchpointType: 'product',
    createdTouchpoints: [],
    stepValidating: false,
    downloading: false,
    isTouchpointExists: false,
  }),
  async created() {
    this.setUpdating(true)
    await this.getBrandSettings()
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      currentBrand: state => state.backoffice.currentClient?.brandId,
      products: state => state.touchpoints.products,
      brandSettings: state => state.touchpoints.brandSettings,
      downloadUrl: state => state.touchpoints.downloadUrl,
    }),
    steps() {
      if (this.touchpointType === 'product') return 3
      else if (this.touchpointType === 'custom') return 2
      return 0
    },
    computedOkProducts() {
      return this.products.filter(product => product.status === 'OK')
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      resetState: 'touchpoints/resetState',
      sortProducts: 'touchpoints/sortProducts',
      resetProducts: 'touchpoints/resetProducts',
      getProduct: 'touchpoints/getProduct',
      getBrandSettings: 'touchpoints/getBrandSettings',
      addProductTouchpoint: 'touchpoints/addProductTouchpoint',
      addCustomTouchpoint: 'touchpoints/addCustomTouchpoint',
      getTouchpoints: 'touchpoints/getTouchpoints',
      getDownloadUrl: 'touchpoints/getDownloadUrl',
      setAlert: 'backoffice/setAlert',
    }),
    updateTouchpointType(type) {
      this.touchpointType = type
    },
    launchStepper() {
      this.hasStepper = true
    },
    previousStep() {
      this.currentStep--
    },
    async getCurrentProducts(references) {
      this.stepValidating = true
      this.resetProducts()
      this.linearProgressBar = true
      const chunkedReferences = chunk(references, 20, [])

      for (const chunkedReference of chunkedReferences) {
        const promises = chunkedReference.map(reference => this.getProduct(reference))
        await Promise.allSettled(promises)
      }
      this.sortProducts(references)
      this.currentStep++
      this.stepValidating = false
    },
    async createProductTouchpoints() {
      this.stepValidating = true
      const validProducts = this.products.filter(
        product => product.status === 'OK' && !product.touchpointConfiguration.activated
      )
      const hasTouchpointProducts = this.products.filter(product => product.status === 'OK').map(product => product.sku)

      const productChunks = chunk(validProducts, 20, [])
      for (const productChunk of productChunks) {
        const promises = productChunk.map(product => this.addProductTouchpoint(product.sku))
        await Promise.allSettled(promises)
      }
      this.createdTouchpoints = await this.getTouchpoints(hasTouchpointProducts)
      this.stepValidating = false
      this.currentStep++
    },
    async createCustomTouchpoint(touchpoint) {
      this.stepValidating = true
      try {
        this.createdTouchpoints = [await this.addCustomTouchpoint(touchpoint)]
        this.currentStep++
        this.isTouchpointExists = false
      } catch ({ status }) {
        if (status === 409) {
          this.isTouchpointExists = true
        } else {
          this.setAlert({
            color: 'error',
            text: this.$t('error.notification.default'),
          })
        }
      }
      this.stepValidating = false
    },
    async downloadQRCodes() {
      this.downloading = true
      const idsTable = this.createdTouchpoints.map(touchpoint => touchpoint._id)
      try {
        const downloadUrl = await this.getDownloadUrl({ _ids: idsTable })
        downloadFromUrl(downloadUrl)
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.downloading = false
    },
    goToTouchpointManagement() {
      this.$router.push({ name: 'TouchpointsList' })
    },
    resetField() {
      this.isTouchpointExists = false
    },
    resetStepper() {
      this.hasStepper = false
    },
  },
}
</script>
<style lang="scss">
.touchpoints-creation {
  &__header {
    border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');
    background: map-get($material-light, 'banner', 'background');

    &.theme--dark {
      border-bottom: 1px solid map-deep-get($material-dark, 'banner', 'border');
      background: map-get($material-dark, 'banner', 'background');
    }
  }

  &__items {
    background: map-get($material-light, 'background');

    &.theme--dark {
      background: map-get($material-dark, 'background');
    }
  }
}
</style>
