<template>
  <v-stepper-content step="1">
    <UiContainer with-actions large>
      <form class="tw-flex tw-flex-col tw-gap-6" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <div>
          <UiTitle :title="$t('touchpoints.creation.custom.form.title')" />
          <UiSubtitle :subtitle="$t('touchpoints.creation.custom.form.subtitle')" />
        </div>
        <div class="tw-flex tw-flex-col">
          <div class="required-asterisk">
            <UiTitle :title="$t('touchpoints.creation.custom.form.field.destination.label')" small />
            <UiSubtitle class="tw-mb-2" :subtitle="$t('touchpoints.creation.custom.form.field.destination.hint')" />
            <v-text-field
              :prepend-inner-icon="icons.mdiBullseyeArrow"
              v-model.trim="urlDest"
              :error-messages="urlDestinationErrors"
              id="urlDest"
              solo
              flat
              outlined
              :placeholder="$t('touchpoints.creation.custom.form.field.destination.placeholder')"
            />
          </div>
          <div class="required-asterisk">
            <UiTitle :title="$t('touchpoints.creation.custom.form.field.shortUrl.label')" small />
            <UiSubtitle class="tw-mb-2" :subtitle="$t('touchpoints.creation.custom.form.field.shortUrl.base.hint')" />
            <div class="tw-flex tw-flex-row tw-align-middle">
              <v-select
                :prepend-inner-icon="icons.mdiQrcode"
                v-model="baseUrl"
                :error-messages="baseUrlErrors"
                id="baseUrl"
                solo
                flat
                outlined
                :items="baseUrlList"
                :item-text="item => item.url.replace(new RegExp('\\/$'), '')"
                item-value="slug"
                :placeholder="$t('touchpoints.creation.custom.form.field.shortUrl.base.placeholder')"
              />
              <div class="tw-mt-4 tw-ml-2 tw-mr-2">/</div>
              <v-text-field
                v-model.trim="urlShort"
                id="urlShort"
                :error-messages="urlShortErrors"
                solo
                flat
                outlined
                :placeholder="$t('touchpoints.creation.custom.form.field.shortUrl.suffix.placeholder')"
                @input="$emit('editing')"
              />
            </div>
          </div>
          <div class="required-asterisk">
            <UiTitle :title="$t('touchpoints.creation.custom.form.field.description.label')" small />
            <UiSubtitle class="tw-mb-2" :subtitle="$t('touchpoints.creation.custom.form.field.description.hint')" />
            <v-text-field
              :prepend-inner-icon="icons.mdiNotebookEditOutline"
              v-model.trim="description"
              :error-messages="descriptionErrors"
              id="description"
              solo
              flat
              outlined
              :placeholder="$t('touchpoints.creation.custom.form.field.description.placeholder')"
            />
          </div>
          <div>
            <UiTitle :title="$t('touchpoints.creation.custom.form.field.quantity.label')" small />
            <UiSubtitle class="tw-mb-2" :subtitle="$t('touchpoints.creation.custom.form.field.quantity.hint')" />
            <v-text-field
              :prepend-inner-icon="icons.mdiPrinterOutline"
              v-model.trim="quantity"
              id="quantity"
              solo
              flat
              outlined
              persistent-hint
              :error-messages="quantityErrors"
              type="number"
              :min="0"
              :placeholder="$t('touchpoints.creation.custom.form.field.quantity.placeholder')"
            />
          </div>
        </div>
        <UiActions large>
          <v-btn color="primary" outlined rounded @click="onPrev">
            <v-icon left>{{ icons.mdiArrowLeft }}</v-icon>
            {{ $t('button.previous') }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" rounded type="submit" :disabled="loading" :loading="loading">
            {{ $t('touchpoints.creation.custom.form.cta.create') }}
            <v-icon right>{{ icons.mdiArrowRight }}</v-icon>
          </v-btn>
        </UiActions>
      </form>
    </UiContainer>
  </v-stepper-content>
</template>

<script>
import {
  mdiArrowRight,
  mdiArrowLeft,
  mdiBullseyeArrow,
  mdiQrcode,
  mdiNotebookEditOutline,
  mdiPrinterOutline,
} from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required, url, minValue } from 'vuelidate/lib/validators'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'TouchpointsCreationCustomForm',
  components: {
    UiTitle,
    UiSubtitle,
    UiActions,
    UiContainer,
  },
  props: {
    baseUrlList: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTouchpointExists: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  data: () => ({
    urlDest: '',
    baseUrl: '',
    urlShort: '',
    description: '',
    quantity: null,
    editing: false,
    icons: {
      mdiArrowRight,
      mdiArrowLeft,
      mdiBullseyeArrow,
      mdiQrcode,
      mdiNotebookEditOutline,
      mdiPrinterOutline,
    },
  }),
  computed: {
    descriptionErrors() {
      const errors = []
      if (!this.$v.description.$dirty) return errors
      !this.$v.description.required && errors.push(this.$t('error.required'))
      return errors
    },
    baseUrlErrors() {
      const errors = []
      if (!this.$v.baseUrl.$dirty) return errors
      !this.$v.baseUrl.required && errors.push(this.$t('error.required'))
      return errors
    },
    urlShortErrors() {
      const errors = []
      if (!this.$v.urlShort.$dirty) return errors
      !this.$v.urlShort.required && errors.push(this.$t('error.required'))
      !this.editing &&
        this.isTouchpointExists &&
        errors.push(this.$t('touchpoints.creation.custom.form.field.shortUrl.error'))
      return errors
    },
    urlDestinationErrors() {
      const errors = []
      if (!this.$v.urlDest.$dirty) return errors
      !this.$v.urlDest.required && errors.push(this.$t('error.required'))
      !this.$v.urlDest.url && errors.push(this.$t('error.url'))
      return errors
    },
    quantityErrors() {
      const errors = []
      if (!this.$v.quantity.$dirty) return errors
      !this.$v.quantity.minValue &&
        errors.push(
          this.$t('error.minValue', {
            minValue: this.$v.quantity.$params.minValue.min,
          })
        )
      return errors
    },
  },
  methods: {
    onPrev() {
      this.$emit('prev')
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('create', {
          destUrl: this.urlDest,
          configurationSlug: this.baseUrl,
          sourceSuffix: this.urlShort,
          description: this.description,
          quantity: this.quantity,
        })
      }
    },
  },
  validations() {
    return {
      urlDest: {
        required,
        url,
      },
      description: {
        required,
      },
      baseUrl: {
        required,
      },
      urlShort: {
        required,
      },
      quantity: {
        minValue: minValue(0),
      },
    }
  },
}
</script>
