<template>
  <div class="tw-h-full tw-flex tw-flex-col">
    <UiContainer>
      <div class="tw-flex tw-flex-col tw-flex-auto tw-gap-8 tw-justify-center tw-items-center tw-h-full">
        <div>
          <UiTitle large :title="$t('touchpoints.creation.typePicker.title')" centered />
          <UiSubtitle :subtitle="$t('touchpoints.creation.typePicker.subtitle')" centered />
        </div>
        <v-item-group
          :value="touchpointType"
          class="tw-flex tw-flex-row tw-justify-center tw-gap-8"
          mandatory
          @change="onChange"
        >
          <v-item v-slot="{ active, toggle }" value="product">
            <v-hover v-slot="{ hover }">
              <v-card
                class="touchpoints-creation-picker__card"
                :class="{
                  'tw-ring-2 tw-ring-primary': active,
                  'tw-bg-black tw-bg-opacity-5': hover,
                }"
                width="300px"
                @click="toggle"
              >
                <v-icon v-if="active" color="primary" class="tw-absolute tw-top-2 tw-right-2">
                  {{ icons.mdiCheckCircle }}
                </v-icon>
                <v-icon :size="72" :color="active ? 'primary' : null">{{ icons.mdiShoppingOutline }}</v-icon>
                <UiTitle
                  class="tw-mt-2 tw-self-start"
                  :title="$t('touchpoints.creation.typePicker.products.title')"
                  small
                />
                <UiSubtitle class="tw-text-left" :subtitle="$t('touchpoints.creation.typePicker.products.subtitle')" />
              </v-card>
            </v-hover>
          </v-item>
          <v-item v-slot="{ active, toggle }" value="custom">
            <v-hover v-slot="{ hover }">
              <v-card
                class="touchpoints-creation-picker__card"
                :class="{
                  'tw-ring-2 tw-ring-primary': active,
                  'tw-bg-black tw-bg-opacity-5': hover,
                }"
                width="300px"
                @click="toggle"
              >
                <v-icon v-if="active" color="primary" class="tw-absolute tw-top-2 tw-right-2">
                  {{ icons.mdiCheckCircle }}
                </v-icon>
                <v-icon :size="72" :color="active ? 'primary' : null">{{ icons.mdiPinwheelOutline }}</v-icon>
                <UiTitle
                  class="tw-mt-2 tw-self-start"
                  :title="$t('touchpoints.creation.typePicker.custom.title')"
                  small
                />
                <UiSubtitle class="tw-text-left" :subtitle="$t('touchpoints.creation.typePicker.custom.subtitle')" />
              </v-card>
            </v-hover>
          </v-item>
        </v-item-group>
        <v-btn color="primary" rounded @click="onClick">
          {{ $t('button.next') }}
          <v-icon right>{{ icons.mdiArrowRight }}</v-icon>
        </v-btn>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { mdiShoppingOutline, mdiPinwheelOutline, mdiArrowRight, mdiCheckCircle } from '@mdi/js'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'TouchpointsCreationTypePicker',
  props: {
    touchpointType: {
      type: String,
      required: true,
    },
  },
  components: {
    UiContainer,
    UiTitle,
    UiSubtitle,
  },
  data: () => ({
    icons: {
      mdiShoppingOutline,
      mdiPinwheelOutline,
      mdiArrowRight,
      mdiCheckCircle,
    },
  }),
  methods: {
    onChange(value) {
      this.$emit('change', value)
    },
    onClick() {
      this.$emit('launchStepper')
    },
  },
}
</script>

<style lang="scss" scoped>
.touchpoints-creation-picker {
  &__card {
    @apply tw-p-6 tw-text-center tw-flex tw-items-center tw-flex-col tw-gap-4;

    &:before {
      background: transparent;
    }
  }
}
</style>
