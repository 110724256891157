<template>
  <v-stepper-content step="3">
    <UiContainer large>
      <div class="tw-flex tw-flex-col tw-gap-6">
        <div>
          <UiTitle
            :title="
              $tc('touchpoints.creation.product.summary.title', createdTouchpoints.length, {
                number: createdTouchpoints.length,
              })
            "
            centered
          />
          <UiSubtitle
            :subtitle="$tc('touchpoints.creation.product.summary.subtitle', createdTouchpoints.length)"
            centered
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-6 tw-mt-6 tw-items-center">
          <div class="tw-flex tw-flex-col">
            <v-btn color="primary" primary rounded :disabled="downloading" :loading="downloading" @click="download">
              <span>{{
                $tc('touchpoints.creation.product.summary.cta.download.label', createdTouchpoints.length)
              }}</span>
            </v-btn>
            <div class="tw-text-xs tw-text-gray-400 tw-text-center tw-mt-1">
              {{ $t('touchpoints.creation.product.summary.cta.download.hint') }}
            </div>
          </div>
          <router-link :to="{ name: 'TouchpointsList' }" class="tw-font-medium">
            {{ $t('touchpoints.creation.product.summary.cta.goTouchpoints') }}
          </router-link>
        </div>
      </div>
    </UiContainer>
  </v-stepper-content>
</template>

<script>
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'TouchpointCreationProductSummary',
  components: {
    UiTitle,
    UiSubtitle,
    UiContainer,
  },
  props: {
    createdTouchpoints: {
      type: Array,
      required: false,
      default: () => [],
    },
    downloading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    download() {
      this.$emit('download')
    },
  },
}
</script>
