var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-stepper-content',{staticClass:"touchpoints-creation-preview",attrs:{"step":"2"}},[_c('UiContainer',{attrs:{"with-actions":"","large":""}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-6"},[_c('div',[_c('UiTitle',{attrs:{"title":_vm.$t('touchpoints.creation.product.preview.title')}}),(_vm.products.length === _vm.existingProducts.length)?_c('UiSubtitle',{attrs:{"subtitle":_vm.$tc('touchpoints.creation.product.preview.subtitle.allOk', _vm.products.length, {
              number: _vm.products.length,
            })}}):_vm._e(),(!_vm.existingProducts.length)?_c('UiSubtitle',{attrs:{"subtitle":_vm.$tc('touchpoints.creation.product.preview.subtitle.allKO', _vm.products.length, {
              number: _vm.products.length,
            })}}):_vm._e(),(_vm.existingProducts.length < _vm.products.length && _vm.existingProducts.length)?_c('UiSubtitle',{attrs:{"subtitle":_vm.$tc('touchpoints.creation.product.preview.subtitle.mixed', _vm.existingProducts.length, {
              number: _vm.products.length,
              results: _vm.existingProducts.length,
            })}}):_vm._e()],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1 touchpoints-creation-preview__table tw-mb-24",attrs:{"disable-sort":"","hide-default-footer":"","item-class":_vm.itemClass,"items-per-page":-1,"headers":_vm.headers,"items":_vm.products},scopedSlots:_vm._u([{key:`item.number`,fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:`item.touchpointConfiguration.activated`,fn:function({ item }){return [(item.touchpointConfiguration.activated)?_c('v-tooltip',{attrs:{"max-width":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icons.mdiInformation))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('touchpoints.creation.product.preview.table.alreadyExists')))])]):_vm._e()]}}],null,true)})],1),_c('UiActions',{attrs:{"large":""}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","rounded":""},on:{"click":_vm.onPrev}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icons.mdiArrowLeft))]),_vm._v(" "+_vm._s(_vm.$t('button.previous'))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","rounded":"","disabled":!_vm.existingProducts.length || _vm.loading,"loading":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('button.create'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.icons.mdiArrowRight))])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }